$primary: #3588C9;
$lightprimary: #b3e2f6;
$secondary: white;
$accent: #e69600;
$lightaccent: #e6c100;
$border: #212a36;
$dark: #1a1a1a;
$black: $dark;
$offwhite: #f4f4f4;
$white: $offwhite;
$tablehover: $offwhite;
$grey: #808080;
$light-grey: #9c9c9c;
$success: #87CB16;
$danger: #FF4A55;
$error-high: #ab1c00;
$error-medium: #f98d00;
$error-low: #008de6;
$error-info: rgb(44, 100, 255);
$error-none: rgba(0, 158, 0, 0.83);
$topbarheight: 75px;
$break-small: 992px;
$modeQuick: #396791;
$modeStandard: #379E6A;
$modeDeep: #E1714E;


$primaryFont: 'Raleway', sans-serif;
$font: $primaryFont;
$secondaryFont: 'Raleway', sans-serif;
$codeFont: 'Raleway', sans-serif;
$codefont: $codeFont;