@import './constants.scss';

html {
  font-size: 14px;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $primaryFont;
}

main {
  width: 90%;
  margin: 0 auto;
}

.hide-scrollbar {
  scrollbar-width: none;
}
