@import "../../styles/constants.scss";

// base

.btn-primary {
  min-width: 200px;
  margin: 5px;
  height: 40px;
  background: $primary;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.btn-primary:focus {
  background: $accent;
  color: white;
}

.btn-primary:hover {
  color: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-primary:disabled {
  background: rgba(0, 141, 230, 0.7);
}
.btn-primary:disabled:hover {
  background: rgba(0, 141, 230, 0.5);
}

.btn-primary.btn-unselected {
  border: 2px solid rgba(0, 141, 230, 0.7);
  background-color: white;
  color: black;
  text-shadow: none;
}

.btn-primary.btn-unselected:hover {
  border: 2px solid rgba(0, 141, 230, 0.7);
  background-color: white;
  color: black;
}

.btn-primary.btn-selected {
  background: $accent;
  color: white;
  letter-spacing: 2px;
}

.btn.btn-primary.btn-mythx {
  margin-left: 0px;
}

.disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.3;
}

.grid-row-wrapper {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;
}

.grid-column-wrapper {
  display: grid;
  grid-auto-flow: column;
}

.grid-place-center {
  place-self: center;
  place-items: center;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  margin-bottom: 30px;
  background: white;
  animation: fadeLeftIn 0.25s ease-in;
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.2s linear;
  padding-top: 50px;
  padding-bottom: 50px;
}

// custom

.card.header {
  padding: 0px 40px 0px;
  font-weight: 600;
  font-size: 22px;
}
.analyses-report-wrapper {
  margin: 10px;

  .card {
    padding-top: 0px;
    padding-bottom: 5px;
    h1,
    h2,
    h3,
    h4 {
      color: $dark;
    }
    h4.report__datetime {
      padding-top: 10px;
      text-transform: initial;
    }
  }
}

.multi-line {
  line-height: normal !important;
}

.report__datetime {
  font-size: 15px;
  margin: 2px 2px !important;
}

.report__title {
  margin: 0px 0px !important;
  color: black !important;
  font-family: $font;
  font-size: 22px;
  margin-bottom: 5px !important;
}

.report__subtitle {
  margin: 0px 0px !important;
  color: black !important;
  font-family: $font;
  font-size: 22px;
  margin: 40px 0px 20px 0px !important;
}

.report__toggleerrors {
  background: #008de6;
  width: 100%;
}

.report__errorscount {
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px black solid;
  display: flex;
  justify-content: space-around;
}

.report__issuetable {
  padding-bottom: 10px;
  overflow-x: auto;
}

.row.report__issuetable h4 {
  margin: 10px 0px;
}

.report__issueline {
  display: grid;
  grid-template-columns: 100px 100px repeat(2, 2fr) 1fr;
  grid-gap: 20px;
  align-items: center;
  border-top: 1px solid #ddd;
  padding: 4px;
  letter-spacing: 1px;
  padding: 10px 4px;
}

.report__issueline p {
  margin: initial;
}

.report__issueline:hover {
  background-color: #f9f9f9;
}

.report__issueline:nth-child(even) {
  background-color: #f9f9f9;
}

h3.report-header-uuid {
  text-transform: initial;
  color: $dark;
}

.internal-link {
  &--blue {
    color: $primary;
  }
}

.card-report {
  margin: 0px 20px;
  padding: 0px 20px;
}

.coverage-chart-wrapper {
  &.coverage-chart-wrapper--disabled {
    height: auto;
  }
  &__description,
  &__description--small-case {
    padding-top: 10px;
    text-transform: initial;
    font-size: 15px;
    margin: 2px 2px !important;
  }
}

.coverage-chart {
  height: 300px;
}

.issues__errornumbers {
  margin: 0px 20px 0px 0px;
}

.issues__errornumbers--high {
  color: $error-high;
}

.issues__errornumbers--medium {
  color: $error-medium;
}

.issues__errornumbers--low {
  color: $error-low;
}

.pointer {
  cursor: pointer;
}

.no-issues-wrapper {
  display: grid;
  place-items: center;
  &__image {
    max-width: 400px;
    width: 100%;
  }
}

.analysedfiles__mobiletext {
  display: none;
  margin-bottom: 10px;
}

@media (max-width: 11000px) {
  .btn-show-code {
    display: none;
  }

  .analysedfiles__mobiletext {
    display: inline-block;
  }
}

.btn-show-code,
.btn-show-code:active,
.btn-show-code:hover:active {
  font-size: 12px;
  background: white;
  color: $primary;
  border: 2px solid $primary;
}

.btn-show-code:hover {
  background: white;
  color: $primary;
  border: 2px solid $primary;
}

.btn-show-code.disabled {
  background-color: white;
}

@media (max-width: 11000px) {
  .btn-show-code {
    display: none;
  }

  .analysedfiles__mobiletext {
    display: inline-block;
  }
}

.analysedfile__icon {
  margin-right: 20px;
  transition: all 0.2s linear;
}

.analysedfile__icon--issues {
  position: relative;
  top: 8px;
  font-size: 34px;
  margin-right: 15px;
}

.analysedfile__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.1s linear;
}

.analysedfile__arrow {
  transition: all 0.2s linear;
}

.analysedfile__container {
  background: #008de6;
  color: white;
  font-size: 15px;
  padding: 20px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.analysedfile__errorssecuritynumber {
  font-size: 22px;
  font-weight: bold;
  top: 1px;
  left: -2px;
  position: relative;
  margin-right: 5px;
}

.analysedfile__container--margin {
  margin: 5px 0px;
}

.analysedfile__container:hover {
  transform: scale(1.01);
}

.analysedfile__container--none {
  background: $error-none;
}

.analysedfile__container--low {
  background: $error-low;
}

.analysedfile__container--medium {
  background: $error-medium;
}

.analysedfile__container--high {
  background: $error-high;
}

.analysedfile__errors {
  display: flex;
  justify-content: space-around;
  padding: 5px 20px;
  cursor: pointer;

  background: #252525;
}

.analysedfile__errorsseverity {
  font-size: 18px;
  font-family: $codefont;
}

.analysedfile__errorsseverity--high {
  color: $error-high;
}

.analysedfile__errorsseverity--medium {
  color: $error-medium;
}

.analysedfile__errorsseverity--low {
  color: $error-low;
}

.analysedfile__container:hover {
  box-shadow: -4px 4px 2px 0px rgba(0, 0, 0, 0.5);
}

.analysedfile__container:hover .analysedfile__arrow {
  transform: scale(1.3);
}

.analysedfile__container:hover .analysedfile__icon {
  transform: scale(1.3);
}

.analysedfile__container:hover .analysedfile__name {
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.source-code-block {
  background: rgb(37, 37, 37);
  color: rgb(245, 245, 245);
  font-family: monospace;
  margin-bottom: 10px;
  overflow-x: auto;
}

.vulnerability-severity {
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.source-swc-number {
  letter-spacing: 2px;
  padding: 2px;
}

.source-swc-badge {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
  margin-left: 10px;
}

.report__topbar {
  position: relative;
  padding: 0px 5px;
  background: $primary;
  display: grid;
  grid-template-columns: 50px auto auto 1fr auto 50px;
  grid-gap: 5px;
  place-items: center;
  color: white;
  p {
    margin: none;
  }
  &__go-back {
    color: white;
    font-size: 40px;
    width: 50px;
    margin-left: 10px;
  }

  &__scan-badge {
    color: white;

    .badge-wrapper {
      display: inline-block;
      background: white;
      color: $primary;
      font-weight: bold;
      padding: 2px 7px;
      margin-right: 4px;
      border-radius: 10px;
      text-transform: capitalize;
    }
  }

  &__filename,
  &__submission-date {
    color: white;
    margin: initial;
  }

  &__filename {
    margin-left: 20px;
  }

  &__menu-button {
    width: 50px;
    font-size: 30px;
    color: white;
    background: none;
    border: none;
  }

  &__menu {
    display: grid;
    grid-auto-flow: row;
    color: black;
    background: rgba(255, 255, 255, 0.788);
    border: 3px solid #3588c9;
    border-radius: 10px;
    position: absolute;
    top: 60px;
    right: calc(0% + 15px);
    height: 150px;
    width: 220px;
    padding: 8px;
  }

  &__menu-option {
    padding-top: 4px;
    padding-left: 8px;
    &:hover {
      cursor: pointer;
      background: #f4ecec;
      border-radius: 10px;
    }
  }
}

.isHidden {
  display: none;
}

.report-filter {
  &__wrapper {
    display: grid;
    grid-template-columns: auto repeat(3, 1fr);
    max-width: 600px;
    row-gap: 10px;
    margin: 20px 0px;
  }

  &__category {
    margin-right: 30px;
  }

  &__ignored {
    &:hover::after {
      position: absolute;
      height: 6em;
      width: 27em;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.82);
      color: black;
      border: 3px solid $primary;
      padding: 6px 12px;
      border-radius: 10px;
      content: "Select to display or hide issues ignored by comments in the source code. Follow the link for instructions on how to use these ignore comments.";
      word-wrap: unset;
      text-align: justify;
      top: 20em;
      left: 2em;
    }
  }

  &__more-info-img {
    position: relative;
    max-height: 15px;
    max-width: 15px;
    &:hover {
      cursor: pointer;
    }
  }

  &__value {
    border-bottom: 3px solid white;
    text-align: center;
    margin: 4px 2em;
    text-transform: capitalize;

    &--ignored {
      margin: 4px 1em;
    }

    &--low {
      border-color: $error-low;
    }

    &--medium {
      border-color: $error-medium;
      margin: 4px 1em;
    }

    &--high {
      border-color: $error-high;
    }

    &:hover {
      cursor: pointer;
    }
    &--selected {
      font-weight: bold;
    }
  }
}
