@import '../../styles/constants.scss';

$codefont: monospace;



.mythx-source-code-viewer {
  background-color: #272822;
  font-size: 16px;
  color: black;
  display: none;

  &--visible {
    display: initial;
  }

  .not-visible {
    visibility: hidden;
  }

  pre {
    border: none;
  }

  code {
    margin-left: 1.5em;
    tab-size: 1;
    -moz-tab-size: 1;
    position: relative;
    &::before {
      content: attr(data-line-number);
      position: absolute;
      color: grey;
      border-right: 1px solid gray;
      padding-right: 0.8em;
      width: 2.5em;
      left: -3em;
    }
    &[data-line-covered='true']:after {
      position: absolute;
      width: 6px;
      height: 20px;
      background: #3a9f40;
      top: 0px;
      left: -1em;
      content: '';
    }

    &.language-sol {
      &--empty {
        height: 1.5em;
      }
    }
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .super-code {
    background-color: #272822;
    .line-highlight {
      width: 49px;
      background: #00800061;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }

  .mythx-highlight {
    &__code-block {
      position: relative;
      padding-left: 3.8em;
      margin-top: -1.7em;
    }
    &__code-line {
      color: white;
      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      margin-left: 0.7em;
      &:before {
        content: attr(data-custom-line-number);
        color: #999;
        text-align: right;
        pointer-events: none;
        font-size: 100%;
        letter-spacing: -1px;
        border-right: 1px solid #999;
        position: absolute;
        left: 0px;
        width: 3em;
        text-align: center;
      }
    }
  }

  .columns-highlight {
    height: 4px;
    font-size: 13px;
    position: relative;
    border: 3px solid #d4cdcd;
    border-radius: 10px;
    cursor: pointer;

    &--highlighted,
    &:hover {
      animation: pulse 0.5s 1;
      border-color: #e45d5d;
    }

    @keyframes pulse {
      0% {
        border-color: #d4cdcd;
      }
      100% {
        border-color: #e45d5d;
      }
    }

    &--multiple-lines {
      &:hover {
        &:after {
          position: relative;
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid black;
          content: attr(data-message);
          top: -25px;
          width: 112px;
          font-size: 0.8em;
          border-radius: 5px;
          right: 6px;
          padding: 1px 7px;
          font-size: 0.9em;
        }
      }
    }
  }

  .issue-block {
    position: relative;
    display: grid;
    grid-template: 1fr 45px / 25px 1fr;
    margin: 10px 0px;
    padding: 10px 0px;
    background-color: #f5f1ff;
    outline: none;
    scroll-margin-top: 100px;
    min-width: 520px;
    overflow-x: scroll;

    .popup-menu {
      &__option {
        &:hover {
          cursor: pointer;
          background: #00000026;
          outline: 6px solid #00000026;
        }
      }
    }

    &--highlighted,
    &:hover {
      outline: 4px solid #e45d5d;
    }

    &__ignored-badge {
      position: absolute;
      bottom: 71%;
      left: calc(95% - 90px);
      text-transform: lowercase;
      background: #979797;
      padding: 1px 10px;
      border-radius: 24px;
      color: white;
      font-weight: bold;
    }

    &__popup-menu {
      position: absolute;
      width: 200px;
      min-height: 96px;
      top: 15%;
      left: calc(97% - 200px);
      display: none;
      background: #f5f1ffc9;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
      border: 3px solid #272822;
      border-radius: 5px;
      padding: 2px 5px;
      z-index: 2;
      &--active {
        display: initial;
      }
      &-icon {
        position: absolute;
        bottom: 80%;
        left: 95%;
        font-size: 1.5em;
        cursor: pointer;
      }
    }

    &__severity-bar {
      background-color: #bb2200;
      grid-area: 1 / 1 / 3 / 1;
      margin: 0px 8px;
      border-radius: 40px;

      &--high {
        background-color: #bb2200;
      }
      &--medium {
        background-color: #f1874b;
      }
      &--low {
        background-color: #4b8ef1;
      }
    }

    &__footer-section {
      grid-area: 2 / 2;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      margin-left: 10px;
      align-items: end;

      &-left {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 20px;
        align-items: end;
      }

      &-right {
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px);
        justify-content: end;
        align-items: end;
      }

      .footer-section {
        &__swcLink {
          margin-bottom: 0px;
          text-decoration: underline;
          color: black;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .text-section {
      max-height: 125px;
      height: 80px;
      overflow-y: scroll;
      margin-left: 10px;
      &__head {
        font-size: 16px;
        font-weight: bold;
        margin: 0px;
      }

      &__tail {
        font-size: 12px;
        margin: 0px;
        margin-top: 8px;
        padding-right: 1%;
      }
    }

    .severity-badge {
      &__icon {
        margin-right: 5px;
        height: 15px;
        width: 15px;
      }

      &__label {
        text-transform: capitalize;
        font-family: 'Raleway', sans-serif;
        // font-size: 12px;
      }

      &__icon--high {
        circle {
          fill: #bb2200;
        }
      }
      &__icon--medium {
        circle {
          fill: #f1874b;
        }
      }
      &__icon--low {
        circle {
          fill: #4b8ef1;
        }
      }
    }
  }

  .terciary-button {
    padding: 0px;
    &__label {
      padding: 0px;
      font-family: 'Raleway', sans-serif;
      color: black;
      margin-bottom: 0px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .block-comment {
    color: gray !important;

    & * {
      color: gray !important;
    }
  }
}

code {
  font-size: 13px;
}

.issue-block-traces-wrapper {
  background: #272822;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
  font-size: 13px;
  border-bottom: 2px solid white;
}

.issue-block-traces-wrapper p,
.issue-block-traces-wrapper span {
  font-family: $codefont;
  font-size: 13px;
}

.issue-block-instruction-number {
  font-weight: bold;
  margin-right: 5px;
  margin-left: 10px;
}

.issue-block-instruction-expand,
.issue-block-instruction-copy {
  color: #3589c9;
  cursor: pointer;
  text-decoration: underline;
}

.issue-block-instruction-tx {
  color: #474747;
  background: white;
  border: 1px solid white;
  border-radius: 20px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-right: 10px;
}

.issue-block-instruction-expanded-line {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.issue-block-instruction-expanded-block {
  margin-left: 45px;
  margin-bottom: 30px;
}

.issue-block-instruction-expanded-block p {
  line-height: 1;
  font-size: 0.8em;
}

.issue-block-instruction-copy.copied {
  color: #0bd70b;
  text-decoration: initial;
}

.issue-block-instruction-title {
  border-bottom: 2px solid white;
  padding-bottom: 8px;
  font-weight: bold;
}

.issue-block-wrapper h4 {
  color: white;
}
