@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@media print {
  @page {
    margin: 0;
    size: A3;
    /*important to force largest braking point*/
  }

  body {
    margin: 1.6cm;
  }

  /* table {page-break-inside: avoid;} */
  nav,
  footer {
    display: none;
  }

  button {
    visibility: hidden;
  }

  header div.max-w-7xl {
    @apply max-w-6xl;
  }

  table {
    border-collapse: collapse;
  }

  thead {
    display: table-header-group;
    margin-top: 20px;
    background: none !important;
  }

  tfoot::after {
    display: block;
    position: relative;
    height: 30px;
    width: 100%;
    background: none;
    content: '';
  }

  thead::before {
    display: block;
    position: relative;
    height: 50px;
    width: 100%;
    background: none;
    content: '';
  }

  div[data-role='faas-report__properties-table-title'] {
    margin-bottom: -23px;
  }

  button[data-role='faas-report__properties-table-location-btn'] {
    /*We want the location button to be displayed because it shows the location 
    of the vulnerability*/
    visibility: initial;
  }

  .source-code-block {
    margin-top: 20px;
  }

  .coverage-chart svg {
    max-width: 900px;
  }

  [data-role='faas-report__properties-table--inline-wrapper'].inline-block {
    display: block;
  }

  [data-role='faas-report__all-source-files'],
  [data-role='faas-report__source-file'] {
    display: none;
  }

  [data-role='faas-report__properties-table-title'] {
    margin-bottom: 0px;
  }

  [data-role='faas-report__properties-table'] div:first-child {
    overflow-x: initial;
  }

  [data-role='faas-report__properties-table--inline-wrapper'] div:first-child {
    --tw-shadow: none;
    box-shadow: none;
  }
}

body {
  @apply bg-gray-50;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.control-pannel__wrapper {
  width: 100%;
}


.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@keyframes AnimationName {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}